@import './main.scss';

.bar-sidebar-left {
    display: block;
    cursor: pointer;
    float: right;
    margin-bottom: 20px;

    &:after,
    &:before {
        content: "";
        width: 100%;
        height: 2px;
        background-color: $burger;
        display: flex;
        border-radius: 10px;
        margin-bottom: 4px;
        margin-top: 4px;
    }
}

.sidebar {
    &-left {
        & .icon-brand {
            display: none;
        }

        & .img-fluid {
            width: 130px;
        }

        & .sidebar-icon {
            width: 17px;
            margin-right: 5px;
            margin-bottom: 5px;
        }

        & .avartar-user {
            width: 150px;
            height: 150px;
            margin-top: 25px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center right;
        }

        & {
            height: 100%;
            width: $width-sidebar;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: $color-white;
            overflow-x: hidden;
            padding-top: 0px;
            padding-left: 20px;
            padding-right: 35px;
            transition: width 0.5s;
        }

        & .li-link,
        li.frist-brand {
            padding: 10px 0px 10px 0px;
            border-bottom: solid 1px $color-borde;
        }

        & .li-link {
            cursor: pointer;
            font-size: 15px;
            color: #aeaeae;
            display: block;

            &:first-of-type {
                border-top: 0px solid;
            }
        }

        & .li-link {
            & li {
                color: #f1f1f1;
                border-bottom: 0px solid white;
                padding: 15px 0px 0px 0px;
            }
        }

        & li:hover {
            color: #f1f1f1;
        }

        & a:hover {
            color: #f1f1f1;
            text-decoration: none;
        }

        & ul {
            list-style: none;
        }
    }

    &-close {
        width: $width-close;
        padding-left: 15px;
        padding-right: 15px;
        transition: width 0.5s;

        & .black {
            height: 45px;
        }

        & .bar-sidebar-left {
            margin: auto;
            float: none;
        }

        & .sidebar-label {
            text-align: left !important;
        }

        & li {
            text-align: center;
        }

        & .icon-brand {
            display: block;
            width: 30px;
            margin: auto;
            margin-bottom: 15px;
            margin-top: 25px;
        }

        & .icon-brand-open {
            display: none;
        }

        & .avartar-user {
            margin-top: 0px;
            width: 45px;
            height: 45px;
        }
    }
}

.layout-main,
.layout-footer {
    margin-left: calc(#{
 $width-sidebar
    }

    - 1rem);
transition: margin-left 0.5s;
}

.layout-sidebar-close {
    margin-left: calc(#{
 $width-close
    }

    - 1rem);
}

.main {
    .mainSideBar {
        display: inline-block;
        float: left;
        border-right: solid 1px #f1f1f1;

        .sidebar-left {
            position: relative;
        }
    }

    .mainContent {
        display: inline-block;
        width: auto;
        vertical-align: top;
        float: right;
        width: 77%;
        margin-bottom: 50px;
    }
}

.sidenav {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    border-right: solid 1px $back-gray-claro;
}

.mainMiSendero {
    margin-left: 300px;
    /* Same as the width of the sidenav */
    padding: 0px 10px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

.rrss {
    a {
        display: inline-block;
    }
}

li.li-link {
    text-align: left;
}

@media (max-width: 769px) {
    .sidenav {
        margin-left: -300px;
        width: 0;
    }

    .mainMiSendero {
        margin-left: 0;
    }
}


.iconMenu {
    width: 25px;
    margin-right: 5px;
    margin-top: -5px;
}

.iconPAT {
    width: 100%;
}

.desactiveMenu {
    .sidenav {
        .botonMenu {
            display: none;
        }
    }
}

.mainContent {
    .botonMenu {
        display: none;
    }
}

@media (max-width: 796px) {
    .activeMenu {
        .sidenav {
            margin-left: 0;
            width: 100%;

            button {
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                border: solid 1px #cccccc;
                background: #ffffff;
                padding: 5px 10px;
                margin: 10px;
            }
        }
    }

    .mainContent {
        .botonMenu {
            display: block;
            position: absolute;
            left: 40px;
            top: 15px;
            border: none !important;
            background: #ffffff;
            padding: 0;
            margin: 10px 0;

            span {
                display: block;
                margin: 4px 0;
                padding: 0;
                line-height: 7px;
                background-color: #2ab4d1;
                width: 25px;
                height: 3px;
                color: #fff;
            }
        }
    }
}