.app-mantenimiento {
    height: 100vh;
    background-size: cover !important;
    background-position: center top !important;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    

    &

    &__difuminado {
        background: rgba(148, 192, 31,.75);
        overflow: hidden;
        height: 100vh;
        background-size: cover !important;
        background-position: center top !important;
        display: flex;
        background-repeat: no-repeat;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        h1 {
            color: #fff;
            font-weight: bold;
            font-size: 60px;
        }

        .app-mantenimiento__description {
            color: rgba(255,255,255,.71);
            font-weight: 300;
            font-size: 18px;
        }

    }
}

@media (max-width: 560px) {
    .app-mantenimiento {

        &__difuminado {
            h1 {
                font-size: 24px !important;
                font-weight: bold;
            }
        
        .app-mantenimient__description {
            font-size: 12px !important;
            text-align: center !important;
        }
    }
}
}