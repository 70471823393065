
.rt-td {
    text-align: center;
}
.admin-transactions-list{
	padding-bottom: 55px;
    border-bottom: dotted 2px #ccc;
	margin-bottom: 50px;
	.title-lists {
		margin-bottom: 15px;
		font-size: 16px;
		font-weight: bold !important;
	}
	.ReactTable{
		.rt-thead.-header {
			box-shadow: none;
		}
		.rt-thead.-filters .rt-th {
			display: none;
		}
		.rt-th{
			background-color: #02a6c8;
            color: #fff;
            padding: 15px 0!important;
		}
	}
}