@import './../../assets/scss/color.scss';

.bt-ms {
    border-radius: 20px;
    width: 100%;
    border: none;
    color: $colorWhite;
    padding: 5px 10%;
    @media (min-width: 768px) {
        padding: 10px 15%;
    }

    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    display: inline-block;
    text-align: center;

    &._bt-green {
        background-color: $colorGreen;

        &:hover {
            background-color: $colorBlue;
        }
    }

    &._bt-blue {
        background-color: $colorBlue;

        &:hover {
            color: $colorSimpleBlue;
        }
    }

    &._bt-simple-blue {
        background-color: $colorSimpleBlue;
        color: $colorBlue;

        &:hover {
            background-color: $colorBlue;
            color: $colorWhite;
        }
    }

    &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}